import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 1200px;
  padding: 400px 30px 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 0px;
  ${MEDIA.SMALLDESKTOP`
          padding: 100px 30px 0;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
        `};
  ${MEDIA.TABLET`
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 80px;
          `};
  a {
    display: block;
    color: #7c7c7c;
  }
  ul {
    li.letter {
      margin: 40px 0px;
      font-size: 3rem;
      font-weight: 700;
    }
  }
`;
