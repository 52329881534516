import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from './allartists.css';

const AllArtistsList = () => {
  const data = useStaticQuery(graphql`
    query AllartistsQuery {
      allContentfulAllArtistsPage {
        edges {
          node {
            artistLongName
            artistLink
            artistFirstNameLetter
            id
          }
        }
      }
    }
  `);

  return (
    <>
      <Container>
        <Fragment>
          <div className="first-letters">
            <ul className="a-letter">
              <li className="letter">A</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'A' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="b-letter">
              <li className="letter">B</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'B' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="c-letter">
              <li className="letter">C</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'C' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="d-letter">
              <li className="letter">D</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'D' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="e-letter">
              <li className="letter">E</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'E' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="f-letter">
              <li className="letter">F</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'F' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="g-letter">
              <li className="letter">G</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'G' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className="second-letters">
            <ul className="h-letter">
              <li className="letter">H</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'H' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="j-letter">
              <li className="letter">J</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'J' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="k-letter">
              <li className="letter">K</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'K' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="l-letter">
              <li className="letter">L</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'L' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="m-letter">
              <li className="letter">M</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'M' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="o-letter">
              <li className="letter">O</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'O' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="p-letter">
              <li className="letter">P</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'P' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className="third-letters">
            <ul className="q-letter">
              <li className="letter">Q</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'Q' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="r-letter">
              <li className="letter">R</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'R' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="s-letter">
              <li className="letter">S</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'S' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="t-letter">
              <li className="letter">T</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'T' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="w-letter">
              <li className="letter">W</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'W' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="y-letter">
              <li className="letter">Y</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'Y' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
            <ul className="z-letter">
              <li className="letter">Z</li>
              {data.allContentfulAllArtistsPage.edges.map(
                edge =>
                  edge.node.artistFirstNameLetter === 'Z' && (
                    <li key={edge.node.id} className="artist">
                      <a
                        href={edge.node.artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.artistLongName}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </div>
        </Fragment>
      </Container>
    </>
  );
};

export default AllArtistsList;
