import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './pagetitle.css';
import Title from 'components/title';

const PageTitle = ({ pagetitle, subtitle, url, icon }) => {
  return (
    <Container>
      <div className="overlay-title">
        <div className="title-button">
          <Title as="h2">{pagetitle}</Title>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
              >
                <path d={icon} fill="#FFFFFF" fillRule="nonzero" />
              </svg>
            </span>
            <span className="text">{subtitle}</span>
          </a>
        </div>
      </div>
    </Container>
  );
};

PageTitle.propTypes = {
  pagetitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.any,
};

export default PageTitle;
