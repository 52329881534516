import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  ${MEDIA.SMALLDESKTOP`
      position: relative;
      height: 300px;
      top: auto;
      left: auto;
  `};
  .overlay-title {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 1);
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    transition: background-color 0.5s ease-in;
    .title-button {
      max-width: 1200px;
      padding: 0 30px;
      width: 100%;
      margin: 0px auto;
    }
    h2 {
      display: block;
      font-size: 10rem;
      font-weight: 700;
      line-height: 1;
      ${MEDIA.SMALLDESKTOP`
              font-size: 9rem;
            `};
      ${MEDIA.TABLET`
              font-size: 7rem;
            `};
    }
    a {
      -webkit-appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: inherit;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
      padding: 1rem 5px;
      text-transform: uppercase;
      -webkit-transition: 0.2s background-color ease;
      transition: 0.2s background-color ease;
      width: 100%;
      color: #fff;
      -webkit-letter-spacing: 2px;
      -moz-letter-spacing: 2px;
      -ms-letter-spacing: 2px;
      letter-spacing: 2px;
      span.icon {
        height: 100%;
        width: 20px;
        display: inline-block;
        vertical-align: bottom;
        margin-right: 10px;
      }
    }
  }
`;
